import { useEffect } from 'react';

export const useKeypress = (key, action) => {
  useEffect(() => {
    function onKeydown(e) {
      if (e.key === key) action(e)
    }
    window.addEventListener('keydown', onKeydown);
    return () => window.removeEventListener('keydown', onKeydown);
  });
}
