//https://stackoverflow.com/questions/11381673/detecting-a-mobile-browser
// https://stackoverflow.com/questions/37591279/detect-if-user-is-using-webview-for-android-ios-or-a-regular-browser

import { environmentConstants } from '../_constants';

export const isMobile = function() {
  if (!window.matchMedia("(pointer:coarse)").matches) { return false }
  if (process.env.NODE_ENV == 'development') { return true }
  var standalone = window.navigator.standalone,
  userAgent = window.navigator.userAgent.toLowerCase(),
  safari = /safari/.test(userAgent),
  ios = /iphone|ipod|ipad/.test(userAgent);

  if (ios) {
    if (!standalone && safari) {
      // Safari
      return false;
    } else if (!standalone && !safari) {
      // iOS webview
      return true;
    };
  } else {
    if (userAgent.includes('wv')) {
      // Android webview
      return false;
    } else {
      // Chrome
      return true;
    }
  };
};
