export const serverConstants = {
  STATE_FETCH_DELAY: 3000,
  DESKTOP_STATE_FETCH_DELAY: 300000,
  CHECK_IP_DELAY: 600000,
  CREDENTIALS_DELAY: 5000,

  PLAID_ENV: 'production',
  // ☝️ switch to 'sandbox' to do local stuff w plaid. Also look at constants.py:

  SURVEY_RESPONSE_POST: 1,
  DATA_POST: 4,
  ANNOTATION_POST: 9,

  TEXTFIELD_QUESTION: 1,
  MULTIPLE_CHOICE_QUESTION: 2,
  MULTISELECT_QUESTION: 3,
  CHECKBOX_QUESTION: 4,
  RANKED_SELECTION_QUESTION: 5,
  FILE_UPLOAD_QUESTION: 6,

  WORK_EXPERIENCE_PROFILE_DETAIL: 1,
  COLLEGE_EXPERIENCE_PROFILE_DETAIL: 2,
  HIGH_SCHOOL_EXPERIENCE_PROFILE_DETAIL: 3,
  EXTERNAL_LINK_PROFILE_DETAIL: 4,
  LANGUAGE_PROFILE_DETAIL: 5,
  QUOTE_PROFILE_DETAIL: 6,
  LIFE_EVENT_PROFILE_DETAIL: 7,
  PLACE_LIVED_PROFILE_DETAIL: 8,
  EDUCATIONAL_EXPERIENCE_PROFILE_DETAIL: 9,
  CURRENT_CITY_PROFILE_DETAIL: 10,
  HOMETOWN_PROFILE_DETAIL: 11,
  ROMANTIC_RELATIONSHIP_PROFILE_DETAIL: 12,
  PHONE_NUMBER_PROFILE_DETAIL: 13,
  ADDRESS_PROFILE_DETAIL: 14,
  GENDER_PROFILE_DETAIL: 15,
  BIRTHDAY_PROFILE_DETAIL: 16,
  RELIGION_PROFILE_DETAIL: 17,
  POLITICAL_VIEW_PROFILE_DETAIL: 18,
  ORIENTATION_PROFILE_DETAIL: 19,
  RELATIONSHIP_PROFILE_DETAIL: 20,
  BIO_PROFILE_DETAIL: 21,
  PROFILE_PICTURE_PROFILE_DETAIL: 22,
  COVER_PHOTO_PROFILE_DETAIL: 23,

  IMAGE_UPLOAD: 1,
  VIDEO_UPLOAD: 2,
  RECEIPT_UPLOAD: 3,

  ACTIVE_STATUS_PROFILE_UPDATE: 1,
  EMAIL_PROFILE_UPDATE: 2,
  PASSWORD_PROFILE_UPDATE: 3,
  NAME_PROFILE_UPDATE: 4,
  TIMELINE_ORIENTATION_PROFILE_UPDATE: 5,
  SELECTED_API_IDS_PROFILE_UPDATE: 6,
  APNS_TOKEN_PROFILE_UPDATE: 7,
  CLEAR_NOTIFICATIONS_PROFILE_UPDATE: 8,
  ONBOARD_STATUS_PROFILE_UPDATE: 9,
  TIMELINE_INTERFACE_PROFILE_UPDATE: 10,
  TIMELINE_BETA_PROFILE_UPDATE: 11,
  WALLET_ADDRESS_PROFILE_UPDATE: 12,
  SELECTED_SERVER_PROFILE_UPDATE: 13,
  TEMPERATURE_PREFERENCE_PROFILE_UPDATE: 14,
  PIN_PROFILE_UPDATE: 15,
  PICTURE_URL_PROFILE_UPDATE: 16,
  ACCOUNT_PREFERENCE_PROFILE_UPDATE: 17,
  WEATHER_DISPLAY_PROFILE_UPDATE: 18,
  HEARTRATE_DISPLAY_PROFILE_UPDATE: 19,
  PHONE_PROFILE_UPDATE: 20,
  LOGIN_PREFERENCE_PROFILE_UPDATE: 21,
  LAST_NOTIFICATION_ID_PROFILE_UPDATE: 22,
  APP_STORE_PURCHASE_STATUS_UPDATE: 23,
  BATCH_PROFILE_UPDATE: 24,
  SELECTED_ACCOUNT_PROFILE_UPDATE: 25,
  DISPLAY_APP_PROFILE_UPDATE: 26,

  SECONDS_UNTIL_INACTIVE: 30,
  MAX_UPDATES: 200,
  PAGINATION_VALUE: 10,

  GET_METADATA_REQUEST: 1,

  CONSUMER_PROFILE_TYPE: 1,
  BUSINESS_PROFILE_TYPE: 2,

  TEXT_QUESTION_TYPE: 1,
  SELECT_QUESTION_TYPE: 2,
  DATE_QUESTION_TYPE: 3,
  MULTISELECT_QUESTION_TYPE: 4,
  NUMBER_QUESTION_TYPE: 5,

  SOFTWARE_UPDATE_NOTIFICATION_TYPE: 1,

  LINK_TO_LOCATION_POST_UPDATE: 1,

  PAID_SURVEY: 1,
  PROFILE_DETAIL_SURVEY: 2,

  PAID_SURVEY_RESPONSE: 1,
  PROFILE_DETAIL_SURVEY_RESPONSE: 2,

  SPOTIFY_API_ID: 1,
  FACEBOOK_API_ID: 2,
  PLAID_API_ID: 3,
  LOCATION_DATA_API_ID: 4,
  GMAIL_API_ID: 5,
  CHROME_API_ID: 6,
  OURA_API_ID: 7,
  TWITTER_API_ID: 8,
  GITHUB_API_ID: 9,
  GOOGLE_CALENDAR_API_ID: 10,
  YOUTUBE_API_ID: 11,
  APPLE_HEALTH_API_ID: 12,
  FITBIT_API_ID: 13,
  SAFARI_API_ID: 14,
  REDDIT_API_ID: 15,
  STEAM_API_ID: 16,
  PINTEREST_API_ID: 17,
  GARMIN_API_ID: 18,
  INSTAGRAM_API_ID: 949,

  PARAMS_PROMPT: 1,
  ASSISTANT_PROMPT: 2,

  TRAVEL_THRESHOLD_SECONDS: 120,
  HEARTRATE_LATENCY_SECONDS: 1800,

  DISCOUNT_CODE: 'MYDATA2024',
  CHRIS_FREE_CODE: 'AHNFREE2024X',
  SZARA_CODE: 'SZARA24',
  ARMANDO_CODE: 'CALLYOURHIT',
  CHRIS_PAID_CODE: 'BCRUMBS20',
  PAPAMICHAIL_PAID_CODE: 'ADRENALINECP2024'
};
