import locationMarker from '../_assets/locationMarker.png';
import spotifyLogo from '../_assets/spotifyLogo.png';
import facebookLogo from '../_assets/facebookLogo.png';
import creditCardLogo from '../_assets/creditCardLogo.png';
import gmailLogo from '../_assets/gmailLogo.png';
import ouraLogo from '../_assets/ouraLogo.png';
import chromeLogo from '../_assets/chromeLogo.png';
import safariLogo from '../_assets/safariLogo.png';
import youtubeLogo from '../_assets/youtubeLogo.png';
import gCalLogo from '../_assets/gCalLogo.png';
import githubLogo from '../_assets/githubLogo.png';
import twitterLogo from '../_assets/twitterLogo.png';
import fitbitLogo from '../_assets/fitbitLogo.png';
import appleHealthLogo from '../_assets/appleHealthLogo.png';
import redditLogo from '../_assets/redditLogo.png';
import steamLogo from '../_assets/steamLogo.png';
import pinterestLogo from '../_assets/pinterestLogo.png';
import garminLogo from '../_assets/garminLogo.png';
import instagramLogo from '../_assets/instagramLogo.png';

export const getLogo = function(apiRef) {
  if (!apiRef) { return }
  if (typeof(apiRef) == 'string') {
    if (apiRef == "Location Data") { return locationMarker };
    if (apiRef == "Spotify") { return spotifyLogo };
    if (apiRef == "Facebook") { return facebookLogo };
    if (apiRef == "Gmail") { return gmailLogo };
    if (apiRef == "Oura") { return ouraLogo };
    if (apiRef == "Chrome") { return chromeLogo };
    if (apiRef == "Safari") { return safariLogo };
    if (apiRef == "Youtube") { return youtubeLogo };
    if (apiRef == "Google Calendar") { return gCalLogo };
    if (apiRef == "GitHub") { return githubLogo };
    if (apiRef == "Twitter") { return twitterLogo };
    if (apiRef == "Fitbit") { return fitbitLogo };
    if (apiRef == "Purchase Data") { return creditCardLogo };
    if (apiRef == "Apple Health") { return appleHealthLogo };
    if (apiRef == "Reddit") { return redditLogo };
    if (apiRef == "Steam") { return steamLogo };
    if (apiRef == "Pinterest") { return pinterestLogo };
    if (apiRef == "Garmin") { return garminLogo };
    if (apiRef == "Instagram") { return instagramLogo };
  } else {
    if (apiRef.name == "Location Data") { return locationMarker };
    if (apiRef.name == "Spotify") { return spotifyLogo };
    if (apiRef.name == "Facebook") { return facebookLogo };
    if (apiRef.name == "Gmail") { return gmailLogo };
    if (apiRef.name == "Chrome") { return chromeLogo };
    if (apiRef.name == "Safari") { return safariLogo };
    if (apiRef.name == "Youtube") { return youtubeLogo };
    if (apiRef.name == "Google Calendar") { return gCalLogo };
    if (apiRef.name == "GitHub") { return githubLogo };
    if (apiRef.name == "Twitter") { return twitterLogo };
    if (apiRef.name == "Fitbit") { return fitbitLogo };
    if (apiRef.name == "Purchase Data") { return creditCardLogo };
    if (apiRef.name == "Oura") { return ouraLogo };
    if (apiRef.name == "Apple Health") { return appleHealthLogo };
    if (apiRef.name == "Reddit") { return redditLogo };
    if (apiRef.name == "Steam") { return steamLogo };
    if (apiRef.name == "Pinterest") { return pinterestLogo };
    if (apiRef.name == "Garmin") { return garminLogo };
    if (apiRef.name == "Instagram") { return instagramLogo };
  }
  apiRef = parseInt(apiRef)
  if (apiRef == 4){ return locationMarker };
  if (apiRef == 1) { return spotifyLogo };
  if (apiRef == 2) { return facebookLogo };
  if (apiRef == 5) { return gmailLogo };
  if (apiRef == 6) { return chromeLogo };
  if (apiRef == 14) { return safariLogo };
  if (apiRef == 11) { return youtubeLogo };
  if (apiRef == 10) { return gCalLogo };
  if (apiRef == 9) { return githubLogo };
  if (apiRef == 8) { return twitterLogo };
  if (apiRef == 13) { return fitbitLogo };
  if (apiRef == 3) { return creditCardLogo };
  if (apiRef == 7) { return ouraLogo };
  if (apiRef == 12) { return appleHealthLogo };
  if (apiRef == 15) { return redditLogo };
  if (apiRef == 16) { return steamLogo };
  if (apiRef == 17) { return pinterestLogo };
  if (apiRef == 19) { return garminLogo };
  if (apiRef == 949) { return instagramLogo };
};
