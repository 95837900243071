export const navigationConstants = {
  DATA_VIEW: "Data",
  APPS_VIEW: "Apps",
  MY_ACCOUNT_VIEW: "My Account",
  SETTINGS_VIEW: "Settings",
  VPN_VIEW: "dVPN",
  DATA_LEAKS_VIEW: "Data Leaks",
  MISC_OPTIONS_VIEW: "Options",
  OFFERS_VIEW: "Market",
  SURVEY_SIDEBAR_DEFAULT: "default",
  SURVEY_SIDEBAR_TITLE: 'title',
  SURVEY_SIDEBAR_AUDIENCE: 'audience',
  SURVEY_SIDEBAR_OPTIONS: 'dates',
  SURVEY_SIDEBAR_DATA: 'data',
};
