import React, { Suspense, lazy } from 'react';
import { Provider } from 'react-redux';
import { render } from 'react-dom';

import { store } from './_helpers';
import logo from './_assets/Asset 2.svg';
import sentinelLogo from './_assets/sentinelLogo.png';
import './SplashPage/SplashPage.scss';
import './App/App.scss';
const App = lazy(() => import('./App/App.jsx'));

// import { createRoot } from 'react-dom/client'; this approach breaks dropdown

const fallback = <div className='splash-page'>
  <div className="is-desktop">
    <div className='row'>
      <div className="col-12 col-md-6 offset-0 offset-md-3">
        <div className="row top-row">
          <div className="col-12 text-center">
            <img src={logo} className="logo" alt="logo"/>
            <p className='title'>Loading...</p>
            <p className='bottom-text'>Secured by Sentinel<img src={sentinelLogo} className='sentinel-logo' alt='sentinelLogo'/></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

render(
  <Suspense fallback={fallback}>
    <Provider store={store}>
        <App />
    </Provider>
  </Suspense>,
  document.getElementById('app')
);
