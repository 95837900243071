import { combineReducers } from 'redux';

import { auth } from './auth.reducer';
import { alert } from './alert.reducer';
import { user } from './user.reducer';
import { business } from './business.reducer';

const rootReducer = combineReducers({
  auth,
  alert,
  user,
  business
});

export default rootReducer;
