import { businessConstants, authConstants } from "../_constants";

export function business(state = {}, action) {
  switch (action.type) {
    case businessConstants.GET_STATE_SUCCESS:
    case authConstants.BUSINESS_SIGN_IN_SUCCESS:
    case authConstants.BUSINESS_REGISTER_SUCCESS:
      return action.data.state;

    case businessConstants.UPDATE_SURVEY_STATE:
      state.surveys[action.data.id] = action.data;
      state.surveys[action.data.id].unsavedChanges = true;
      return state;

    case businessConstants.CREATE_SURVEY_SUCCESS:
    case businessConstants.SAVE_SURVEY_SUCCESS:
    case businessConstants.DELETE_SURVEY_SUCCESS:
      state.surveys = {
        ...state.surveys,
        ...action.data.surveys,
      };
      return state;

    default:
      return state;
  }
}
