export const planFeatures = function(planId, isTest) {
  if (isTest) {
    if (planId =="price_1PFiBxAYHQ7MhkpWamsE63fE") { return "monthly dVPN" }
    if (planId =="price_1PFiFAAYHQ7MhkpWyWNE7Ylo") { return "monthly Assistant" }
    if (planId =="price_1PFiFcAYHQ7MhkpWoMH7sbmi") { return "monthly Scanner" }
    if (planId =="price_1PFiCgAYHQ7MhkpWXlnzVGkU") { return "monthly dVPN + Assistant" }
    if (planId =="price_1PFiEWAYHQ7MhkpWNmXWzTxi") { return "monthly dVPN + Scanner" }
    if (planId =="price_1PFiDvAYHQ7MhkpW0lsJmbmk") { return "monthly Assistant + Scanner" }
    if (planId =="price_1PFiDLAYHQ7MhkpWeJ3vIKEc") { return "monthly dVPN + Assistant + Scanner" }
    if (planId =="price_1PFiKbAYHQ7MhkpW1Pc2E0TZ") { return "yearly dVPN" }
    if (planId =="price_1PFiLCAYHQ7MhkpWhrraAxmu") { return "yearly Assistant" }
    if (planId =="price_1PFiLlAYHQ7MhkpWwCL3wych") { return "yearly Scanner" }
    if (planId =="price_1PFiMKAYHQ7MhkpWQ8KS9i6P") { return "yearly dVPN + Assistant" }
    if (planId =="price_1PFiMoAYHQ7MhkpWZw0Xri2D") { return "yearly dVPN + Scanner" }
    if (planId =="price_1PFiNJAYHQ7MhkpWmNx1oknt") { return "yearly Assistant + Scanner" }
    if (planId =="price_1PFiO2AYHQ7MhkpWF6cxCspK") { return "yearly dVPN + Assistant + Scanner" }
    if (planId =="price_1PFiOaAYHQ7MhkpW57e3mWED") { return "2 year dVPN" }
    if (planId =="price_1PFiP8AYHQ7MhkpW1qnCt9xr") { return "2 year Assistant" }
    if (planId =="price_1PFiPgAYHQ7MhkpWpZKQblLk") { return "2 year Scanner" }
    if (planId =="price_1PFiQJAYHQ7MhkpWdQO9PgY2") { return "2 year dVPN + Assistant" }
    if (planId =="price_1PFiQzAYHQ7MhkpW9q0cZKSb") { return "2 year dVPN + Scanner" }
    if (planId =="price_1PFiRaAYHQ7MhkpWAP3eQnaR") { return "2 year Assistant + Scanner" }
    if (planId =="price_1PFiSJAYHQ7MhkpWNf9LTaKJ") { return "2 year dVPN + Assistant + Scanner" }
    if (planId =="price_1PFiflAYHQ7MhkpWnNYb4Bm3") { return "4 Month dVPN" }
    if (planId =="price_1PFiiQAYHQ7MhkpWpqtTLmuE") { return "4 Month Assistant" }
    if (planId =="price_1PFiiyAYHQ7MhkpWz8isLhtC") { return "4 Month Scanner" }
    if (planId =="price_1PFijmAYHQ7MhkpWS1yjdqWT") { return "4 Month dVPN + Assistant" }
    if (planId =="price_1PFikyAYHQ7MhkpW0LykRkY7") { return "4 Month dVPN + Scanner" }
    if (planId =="price_1PFilhAYHQ7MhkpWCE5prliR") { return "4 Month Assistant + Scanner" }
    if (planId =="price_1PFimoAYHQ7MhkpW3j9Pj2XW") { return "4 Month dVPN + Assistant + Scanner" }
    if (planId =="price_1PFioDAYHQ7MhkpW8J0F8SJj") { return "15 Month dVPN" }
    if (planId =="price_1PFiosAYHQ7MhkpWYhJsA1u0") { return "15 Month Assistant" }
    if (planId =="price_1PFipiAYHQ7MhkpWnjkVyZTB") { return "15 Month Scanner" }
    if (planId =="price_1PFiqNAYHQ7MhkpWtIpa4Ikj") { return "15 Month dVPN + Assistant" }
    if (planId =="price_1PFir0AYHQ7MhkpWbbjwKl0E") { return "15 Month dVPN + Scanner" }
    if (planId =="price_1PFirjAYHQ7MhkpWSEL7Vjgq") { return "15 Month Assistant + Scanner" }
    if (planId =="price_1PFisRAYHQ7MhkpWEQg1TG97") { return "15 Month dVPN + Assistant + Scanner" }
    if (planId =="price_1PFitXAYHQ7MhkpWY9GGcT6Z") { return "27 Month dVPN" }
    if (planId =="price_1PFiuAAYHQ7MhkpWOqCvyIEd") { return "27 Month Assistant" }
    if (planId =="price_1PFiucAYHQ7MhkpWMFvG5TCG") { return "27 Month Scanner" }
    if (planId =="price_1PFivIAYHQ7MhkpWeNBfdC4O") { return "27 Month dVPN + Assistant" }
    if (planId =="price_1PFivzAYHQ7MhkpWWb9zzf7G") { return "27 Month dVPN + Scanner" }
    if (planId =="price_1PFiwYAYHQ7MhkpW934A1EgM") { return "27 Month Assistant + Scanner" }
    if (planId =="price_1PFixFAYHQ7MhkpWjlaRyU1y") { return "27 Month dVPN + Assistant + Scanner" }
  }
  if (planId == "price_1PFljKAYHQ7MhkpWSeQn1UhW") { return "monthly dVPN" }
  if (planId == "price_1PFlpIAYHQ7MhkpWHIhRUf4j") { return "monthly Assistant" }
  if (planId == "price_1PFlpfAYHQ7MhkpWVI9xZzqS") { return "monthly Scanner" }
  if (planId == "price_1PFln7AYHQ7MhkpWNAbfTXia") { return "monthly dVPN + Assistant" }
  if (planId == "price_1PFlohAYHQ7MhkpWn0Y4X4at") { return "monthly dVPN + Scanner" }
  if (planId == "price_1PFloJAYHQ7MhkpW3uOcMHwv") { return "monthly Assistant + Scanner" }
  if (planId == "price_1PFlnnAYHQ7MhkpWvShSn0Ea") { return "monthly dVPN + Assistant + Scanner" }

  if (planId == "price_1PFlq1AYHQ7MhkpWHKD9SAMw") { return "yearly dVPN" }
  if (planId == "price_1PFlqNAYHQ7MhkpWTWegBRhK") { return "yearly Assistant" }
  if (planId == "price_1PFlqmAYHQ7MhkpWcAZrGFBY") { return "yearly Scanner" }
  if (planId == "price_1PFlrCAYHQ7MhkpWbBpgd0qk") { return "yearly dVPN + Assistant" }
  if (planId == "price_1PFlrfAYHQ7MhkpWRVO4zuNi") { return "yearly dVPN + Scanner" }
  if (planId == "price_1PFls5AYHQ7MhkpWgLwCGeE1") { return "yearly Assistant + Scanner" }
  if (planId == "price_1PFlsXAYHQ7MhkpWt489fAOI") { return "yearly dVPN + Assistant + Scanner" }

  if (planId == "price_1PFltEAYHQ7MhkpWKRfuGtt8") { return "2 year dVPN" }
  if (planId == "price_1PFltlAYHQ7MhkpWY0YHRrRy") { return "2 year Assistant" }
  if (planId == "price_1PFluFAYHQ7MhkpWuzFebNGt") { return "2 year Scanner" }
  if (planId == "price_1PFluhAYHQ7MhkpWylAUZn4Y") { return "2 year dVPN + Assistant" }
  if (planId == "price_1PFlv6AYHQ7MhkpW7EDItFDM") { return "2 year dVPN + Scanner" }
  if (planId == "price_1PFlvfAYHQ7MhkpWUZyeijzf") { return "2 year Assistant + Scanner" }
  if (planId == "price_1PFlwBAYHQ7MhkpWJdSJ7sYE") { return "2 year dVPN + Assistant + Scanner" }

  if (planId == "price_1PFlwlAYHQ7MhkpWCMU3egyY") { return "4 Month dVPN" }
  if (planId == "price_1PFlxJAYHQ7MhkpWar6pIav5") { return "4 Month Assistant" }
  if (planId == "price_1PFlxlAYHQ7MhkpW03iB7Zh8") { return "4 Month Scanner" }
  if (planId == "price_1PFlyZAYHQ7MhkpWUgojG4a6") { return "4 Month dVPN + Assistant" }
  if (planId == "price_1PFlz2AYHQ7MhkpW85afUzk5") { return "4 Month dVPN + Scanner" }
  if (planId == "price_1PFlzQAYHQ7MhkpWydLDh1QW") { return "4 Month Assistant + Scanner" }
  if (planId == "price_1PFlzuAYHQ7MhkpWgvytuw4y") { return "4 Month dVPN + Assistant + Scanner" }

  if (planId == "price_1PFm0OAYHQ7MhkpWla4qn7TR") { return "15 Month dVPN" }
  if (planId == "price_1PFm0sAYHQ7MhkpWqKjyrp8M") { return "15 Month Assistant" }
  if (planId == "price_1PFm1CAYHQ7MhkpWXQj49DTB") { return "15 Month Scanner" }
  if (planId == "price_1PFm1fAYHQ7MhkpWBq40eth7") { return "15 Month dVPN + Assistant" }
  if (planId == "price_1PFm25AYHQ7MhkpWVAoOorH2") { return "15 Month dVPN + Scanner" }
  if (planId == "price_1PFm2YAYHQ7MhkpW5b1irzHW") { return "15 Month Assistant + Scanner" }
  if (planId == "price_1PFm30AYHQ7MhkpWXFv3UUeI") { return "15 Month dVPN + Assistant + Scanner" }

  if (planId == "price_1PFm3SAYHQ7MhkpW5072N128") { return "27 Month dVPN" }
  if (planId == "price_1PFm40AYHQ7MhkpWTh43a0w9") { return "27 Month Assistant" }
  if (planId == "price_1PFm4bAYHQ7MhkpWjLepExp0") { return "27 Month Scanner" }
  if (planId == "price_1PFm56AYHQ7MhkpWC4FqFUQX") { return "27 Month dVPN + Assistant" }
  if (planId == "price_1PFm5gAYHQ7MhkpWXE1GmXgd") { return "27 Month dVPN + Scanner" }
  if (planId == "price_1PFm6AAYHQ7MhkpWuPaTNjzQ") { return "27 Month Assistant + Scanner" }
  if (planId == "price_1PFm6cAYHQ7MhkpW4nT70eBh") { return "27 Month dVPN + Assistant + Scanner" }
  return ""
};

