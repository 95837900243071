export const businessConstants = {
  UPDATE_SURVEY_STATE: "BUSINESS_UPDATE_SURVEY_STATE",

  GET_STATE_REQUEST: "BUSINESS_GET_STATE_REQUEST",
  GET_STATE_SUCCESS: "BUSINESS_GET_STATE_SUCCESS",
  GET_STATE_FAILURE: "BUSINESS_GET_STATE_FAILURE",

  CREATE_SURVEY_REQUEST: "BUSINESS_CREATE_SURVEY_REQUEST",
  CREATE_SURVEY_SUCCESS: "BUSINESS_CREATE_SURVEY_SUCCESS",
  CREATE_SURVEY_FAILURE: "BUSINESS_CREATE_SURVEY_FAILURE",

  SAVE_SURVEY_REQUEST: "BUSINESS_SAVE_SURVEY_REQUEST",
  SAVE_SURVEY_SUCCESS: "BUSINESS_SAVE_SURVEY_SUCCESS",
  SAVE_SURVEY_FAILURE: "BUSINESS_SAVE_SURVEY_FAILURE",

  DELETE_SURVEY_REQUEST: "BUSINESS_DELETE_SURVEY_REQUEST",
  DELETE_SURVEY_SUCCESS: "BUSINESS_DELETE_SURVEY_SUCCESS",
  DELETE_SURVEY_FAILURE: "BUSINESS_DELETE_SURVEY_FAILURE",
};
