import { userConstants, authConstants } from '../_constants';

export function auth(state = {}, action) {
  switch (action.type) {
    case authConstants.REGISTER_REQUEST:
      return {  ...state, phone_number: action.inputs.phoneNumber };

    case authConstants.REGISTER_SUCCESS:
      return { ...state, loggedIn: true, loggedOut: false };

    case authConstants.SIGN_IN_REQUEST:
      return { ...state, loggingIn: true, loggedOut: false };

    case authConstants.SIGN_IN_SUCCESS:
    case userConstants.GET_STATE_SUCCESS:
      return { ...state, loggingIn: false, loggedIn: true, loggedOut: false };

    case authConstants.SIGN_IN_FAILURE:
      return { loggingIn: false };

    case authConstants.SIGN_OUT_REQUEST:
      return { loggingOut: true };

    case authConstants.SIGN_OUT_SUCCESS:
    case authConstants.DELETE_ACCOUNT_SUCCESS:
      return {
        loggingOut: false,
        loggedIn: false,
        loggedOut: true
      };

    case authConstants.SIGN_OUT_FAILURE:
      return { loggingOut: false };

    case authConstants.SET_CURRENT_STEP_REQUEST:
      return { isSigningIn: action.data.isSigningIn, ...state, currentStep: action.data.currentStep };

    case authConstants.SET_AUTH_PHONE_NUMBER:
      return { ...state, phone_number: action.data };

    case authConstants.SET_AUTH_EMAIL:
      return { ...state, email: action.data.email, code: action.data.code };

    default:
      return state;
  }
}
