import { createStore, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger';

import { userConstants } from '../_constants';
import rootReducer from '../_reducers';

const loggerMiddleware = createLogger();

const isDemo = window.location.href.includes("demo");

function guidGenerator() {
    var S4 = function() {
       return (((1+Math.random())*0x10000)|0).toString(16).substring(1);
    };
    return (S4()+S4()+"-"+S4()+"-"+S4()+"-"+S4()+"-"+S4()+S4()+S4());
}

window.incognitoLocalStorage = {
  getItem: () => {},
  setItem: () => {},
};
try {
  incognitoLocalStorage = localStorage;
} catch(e) {}

if (!incognitoLocalStorage.getItem('deviceId')) {
  incognitoLocalStorage.setItem('deviceId', guidGenerator())
}

var initialState = {'user': {}};

const properties = ['apis', 'apps', 'leaks', 'locations', 'sales', 'prompts', 'profile_details', 'profile_detail_questions', 'me', 'notifications', 'servers', 'posts', 'source_requests', 'user_uploads', 'offer_responses', 'buyers', 'weather_snapshots', 'offers', 'demo_state', 'polylines', 'user_agent'];

try {
  properties.forEach((property) => {
    const value = incognitoLocalStorage.getItem(`Breadcrumbs_${property}`)
    initialState['user'][property] = value && value != 'undefined' && !isDemo ? JSON.parse(value) : property == 'servers' ? [] : {}
  })
  initialState['auth'] = incognitoLocalStorage.getItem(`Breadcrumbs_auth`) ? JSON.parse(incognitoLocalStorage.getItem(`Breadcrumbs_auth`)) : {}
} catch (error) { console.log(error) } // this happens when we run into storage limits with incognitoLocalStorage; should address at some point

const saverMiddleware = (store) => next => action => {
  let result = next(action);
  let state = store.getState();
  try {
      incognitoLocalStorage.setItem("Breadcrumbs_auth", JSON.stringify(state.auth))
  } catch(e) {
    console.log('error saving auth', e)
  }
  properties.forEach((property) => {
    try {
      incognitoLocalStorage.setItem(`Breadcrumbs_${property}`, JSON.stringify(state.user[property]))
    } catch(e) {
      console.log(`error saving ${property}`, e)
    }
  })
  return result;
}


export const store = createStore(
  rootReducer,
  initialState,
  applyMiddleware(
    thunkMiddleware,
    loggerMiddleware,
    saverMiddleware
  )
);
