import { useEffect } from 'react';

export const useOnClick = (className, action) => {
  useEffect(() => {
    function onClick(e) {
      if (e.target.classList.contains(className)) action(e)
    }
    window.addEventListener('click', onClick);
    return () => window.removeEventListener('click', onClick);
  });
}
