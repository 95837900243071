import ad from "../_assets/flags/ad.svg"
import ae from "../_assets/flags/ae.svg"
import af from "../_assets/flags/af.svg"
import ag from "../_assets/flags/ag.svg"
import ai from "../_assets/flags/ai.svg"
import al from "../_assets/flags/al.svg"
import am from "../_assets/flags/am.svg"
import ao from "../_assets/flags/ao.svg"
import aq from "../_assets/flags/aq.svg"
import ar from "../_assets/flags/ar.svg"
import as from "../_assets/flags/as.svg"
import at from "../_assets/flags/at.svg"
import au from "../_assets/flags/au.svg"
import aw from "../_assets/flags/aw.svg"
import ax from "../_assets/flags/ax.svg"
import az from "../_assets/flags/az.svg"
import ba from "../_assets/flags/ba.svg"
import bb from "../_assets/flags/bb.svg"
import bd from "../_assets/flags/bd.svg"
import be from "../_assets/flags/be.svg"
import bf from "../_assets/flags/bf.svg"
import bg from "../_assets/flags/bg.svg"
import bh from "../_assets/flags/bh.svg"
import bi from "../_assets/flags/bi.svg"
import bj from "../_assets/flags/bj.svg"
import bl from "../_assets/flags/bl.svg"
import bm from "../_assets/flags/bm.svg"
import bn from "../_assets/flags/bn.svg"
import bo from "../_assets/flags/bo.svg"
import bq from "../_assets/flags/bq.svg"
import br from "../_assets/flags/br.svg"
import bs from "../_assets/flags/bs.svg"
import bt from "../_assets/flags/bt.svg"
import bv from "../_assets/flags/bv.svg"
import bw from "../_assets/flags/bw.svg"
import by from "../_assets/flags/by.svg"
import bz from "../_assets/flags/bz.svg"
import ca from "../_assets/flags/ca.svg"
import cc from "../_assets/flags/cc.svg"
import cd from "../_assets/flags/cd.svg"
import cf from "../_assets/flags/cf.svg"
import cg from "../_assets/flags/cg.svg"
import ch from "../_assets/flags/ch.svg"
import ci from "../_assets/flags/ci.svg"
import ck from "../_assets/flags/ck.svg"
import cl from "../_assets/flags/cl.svg"
import cm from "../_assets/flags/cm.svg"
import cn from "../_assets/flags/cn.svg"
import co from "../_assets/flags/co.svg"
import cr from "../_assets/flags/cr.svg"
import cu from "../_assets/flags/cu.svg"
import cv from "../_assets/flags/cv.svg"
import cw from "../_assets/flags/cw.svg"
import cx from "../_assets/flags/cx.svg"
import cy from "../_assets/flags/cy.svg"
import cz from "../_assets/flags/cz.svg"
import de from "../_assets/flags/de.svg"
import dj from "../_assets/flags/dj.svg"
import dk from "../_assets/flags/dk.svg"
import dm from "../_assets/flags/dm.svg"
import dz from "../_assets/flags/dz.svg"
import ec from "../_assets/flags/ec.svg"
import ee from "../_assets/flags/ee.svg"
import eg from "../_assets/flags/eg.svg"
import eh from "../_assets/flags/eh.svg"
import er from "../_assets/flags/er.svg"
import es from "../_assets/flags/es.svg"
import et from "../_assets/flags/et.svg"
import fi from "../_assets/flags/fi.svg"
import fj from "../_assets/flags/fj.svg"
import fk from "../_assets/flags/fk.svg"
import fm from "../_assets/flags/fm.svg"
import fo from "../_assets/flags/fo.svg"
import fr from "../_assets/flags/fr.svg"
import ga from "../_assets/flags/ga.svg"
import gb from "../_assets/flags/gb.svg"
import gd from "../_assets/flags/gd.svg"
import ge from "../_assets/flags/ge.svg"
import gf from "../_assets/flags/gf.svg"
import gg from "../_assets/flags/gg.svg"
import gh from "../_assets/flags/gh.svg"
import gi from "../_assets/flags/gi.svg"
import gl from "../_assets/flags/gl.svg"
import gm from "../_assets/flags/gm.svg"
import gn from "../_assets/flags/gn.svg"
import gp from "../_assets/flags/gp.svg"
import gq from "../_assets/flags/gq.svg"
import gr from "../_assets/flags/gr.svg"
import gs from "../_assets/flags/gs.svg"
import gt from "../_assets/flags/gt.svg"
import gu from "../_assets/flags/gu.svg"
import gw from "../_assets/flags/gw.svg"
import gy from "../_assets/flags/gy.svg"
import hk from "../_assets/flags/hk.svg"
import hm from "../_assets/flags/hm.svg"
import hn from "../_assets/flags/hn.svg"
import hr from "../_assets/flags/hr.svg"
import ht from "../_assets/flags/ht.svg"
import hu from "../_assets/flags/hu.svg"
import id from "../_assets/flags/id.svg"
import ie from "../_assets/flags/ie.svg"
import il from "../_assets/flags/il.svg"
import im from "../_assets/flags/im.svg"
import io from "../_assets/flags/io.svg"
import iq from "../_assets/flags/iq.svg"
import ir from "../_assets/flags/ir.svg"
import is from "../_assets/flags/is.svg"
import it from "../_assets/flags/it.svg"
import india from "../_assets/flags/in.svg"
import je from "../_assets/flags/je.svg"
import jm from "../_assets/flags/jm.svg"
import jo from "../_assets/flags/jo.svg"
import jp from "../_assets/flags/jp.svg"
import ke from "../_assets/flags/ke.svg"
import kg from "../_assets/flags/kg.svg"
import kh from "../_assets/flags/kh.svg"
import ki from "../_assets/flags/ki.svg"
import km from "../_assets/flags/km.svg"
import kn from "../_assets/flags/kn.svg"
import kp from "../_assets/flags/kp.svg"
import kr from "../_assets/flags/kr.svg"
import kw from "../_assets/flags/kw.svg"
import ky from "../_assets/flags/ky.svg"
import kz from "../_assets/flags/kz.svg"
import la from "../_assets/flags/la.svg"
import lb from "../_assets/flags/lb.svg"
import lc from "../_assets/flags/lc.svg"
import li from "../_assets/flags/li.svg"
import lk from "../_assets/flags/lk.svg"
import lr from "../_assets/flags/lr.svg"
import ls from "../_assets/flags/ls.svg"
import lt from "../_assets/flags/lt.svg"
import lu from "../_assets/flags/lu.svg"
import lv from "../_assets/flags/lv.svg"
import ly from "../_assets/flags/ly.svg"
import ma from "../_assets/flags/ma.svg"
import mc from "../_assets/flags/mc.svg"
import md from "../_assets/flags/md.svg"
import me from "../_assets/flags/me.svg"
import mf from "../_assets/flags/mf.svg"
import mg from "../_assets/flags/mg.svg"
import mh from "../_assets/flags/mh.svg"
import mk from "../_assets/flags/mk.svg"
import ml from "../_assets/flags/ml.svg"
import mm from "../_assets/flags/mm.svg"
import mn from "../_assets/flags/mn.svg"
import mo from "../_assets/flags/mo.svg"
import mp from "../_assets/flags/mp.svg"
import mq from "../_assets/flags/mq.svg"
import mr from "../_assets/flags/mr.svg"
import ms from "../_assets/flags/ms.svg"
import mt from "../_assets/flags/mt.svg"
import mu from "../_assets/flags/mu.svg"
import mv from "../_assets/flags/mv.svg"
import mw from "../_assets/flags/mw.svg"
import mx from "../_assets/flags/mx.svg"
import my from "../_assets/flags/my.svg"
import mz from "../_assets/flags/mz.svg"
import na from "../_assets/flags/na.svg"
import nc from "../_assets/flags/nc.svg"
import ne from "../_assets/flags/ne.svg"
import nf from "../_assets/flags/nf.svg"
import ng from "../_assets/flags/ng.svg"
import ni from "../_assets/flags/ni.svg"
import nl from "../_assets/flags/nl.svg"
import no from "../_assets/flags/no.svg"
import np from "../_assets/flags/np.svg"
import nr from "../_assets/flags/nr.svg"
import nu from "../_assets/flags/nu.svg"
import nz from "../_assets/flags/nz.svg"
import om from "../_assets/flags/om.svg"
import pa from "../_assets/flags/pa.svg"
import pe from "../_assets/flags/pe.svg"
import pf from "../_assets/flags/pf.svg"
import pg from "../_assets/flags/pg.svg"
import ph from "../_assets/flags/ph.svg"
import pk from "../_assets/flags/pk.svg"
import pl from "../_assets/flags/pl.svg"
import pm from "../_assets/flags/pm.svg"
import pn from "../_assets/flags/pn.svg"
import pr from "../_assets/flags/pr.svg"
import ps from "../_assets/flags/ps.svg"
import pt from "../_assets/flags/pt.svg"
import pw from "../_assets/flags/pw.svg"
import py from "../_assets/flags/py.svg"
import qa from "../_assets/flags/qa.svg"
import re from "../_assets/flags/re.svg"
import ro from "../_assets/flags/ro.svg"
import rs from "../_assets/flags/rs.svg"
import ru from "../_assets/flags/ru.svg"
import rw from "../_assets/flags/rw.svg"
import sa from "../_assets/flags/sa.svg"
import sb from "../_assets/flags/sb.svg"
import sc from "../_assets/flags/sc.svg"
import sd from "../_assets/flags/sd.svg"
import se from "../_assets/flags/se.svg"
import sg from "../_assets/flags/sg.svg"
import sh from "../_assets/flags/sh.svg"
import si from "../_assets/flags/si.svg"
import sj from "../_assets/flags/sj.svg"
import sk from "../_assets/flags/sk.svg"
import sl from "../_assets/flags/sl.svg"
import sm from "../_assets/flags/sm.svg"
import sn from "../_assets/flags/sn.svg"
import so from "../_assets/flags/so.svg"
import sr from "../_assets/flags/sr.svg"
import ss from "../_assets/flags/ss.svg"
import st from "../_assets/flags/st.svg"
import sv from "../_assets/flags/sv.svg"
import sx from "../_assets/flags/sx.svg"
import sy from "../_assets/flags/sy.svg"
import sz from "../_assets/flags/sz.svg"
import tc from "../_assets/flags/tc.svg"
import td from "../_assets/flags/td.svg"
import tf from "../_assets/flags/tf.svg"
import tg from "../_assets/flags/tg.svg"
import th from "../_assets/flags/th.svg"
import tj from "../_assets/flags/tj.svg"
import tk from "../_assets/flags/tk.svg"
import tl from "../_assets/flags/tl.svg"
import tm from "../_assets/flags/tm.svg"
import tn from "../_assets/flags/tn.svg"
import to from "../_assets/flags/to.svg"
import tr from "../_assets/flags/tr.svg"
import tt from "../_assets/flags/tt.svg"
import tv from "../_assets/flags/tv.svg"
import tw from "../_assets/flags/tw.svg"
import tz from "../_assets/flags/tz.svg"
import ua from "../_assets/flags/ua.svg"
import ug from "../_assets/flags/ug.svg"
import um from "../_assets/flags/um.svg"
import us from "../_assets/flags/us.svg"
import uy from "../_assets/flags/uy.svg"
import uz from "../_assets/flags/uz.svg"
import va from "../_assets/flags/va.svg"
import vc from "../_assets/flags/vc.svg"
import ve from "../_assets/flags/ve.svg"
import vg from "../_assets/flags/vg.svg"
import vi from "../_assets/flags/vi.svg"
import vn from "../_assets/flags/vn.svg"
import vu from "../_assets/flags/vu.svg"
import wf from "../_assets/flags/wf.svg"
import ws from "../_assets/flags/ws.svg"
import ye from "../_assets/flags/ye.svg"
import yt from "../_assets/flags/yt.svg"
import za from "../_assets/flags/za.svg"
import zm from "../_assets/flags/zm.svg"
import zw from "../_assets/flags/zw.svg"

const countryMapping = {
  'ANDORRA': 'ad',
  'UNITED ARAB EMIRATES': 'ae',
  'AFGHANISTAN': 'af',
  'ANTIGUA AND BARBUDA': 'ag',
  'ANGUILLA': 'ai',
  'ALBANIA': 'al',
  'ARMENIA': 'am',
  'ANGOLA': 'ao',
  'ANTARCTICA': 'aq',
  'ARGENTINA': 'ar',
  'AMERICAN SAMOA': 'as',
  'AUSTRIA': 'at',
  'AUSTRALIA': 'au',
  'ARUBA': 'aw',
  'ÅLAND ISLANDS': 'ax',
  'AZERBAIJAN': 'az',
  'BOSNIA AND HERZEGOVINA': 'ba',
  'BARBADOS': 'bb',
  'BANGLADESH': 'bd',
  'BELGIUM': 'be',
  'BURKINA FASO': 'bf',
  'BULGARIA': 'bg',
  'BAHRAIN': 'bh',
  'BURUNDI': 'bi',
  'BENIN': 'bj',
  'SAINT BARTHÉLEMY': 'bl',
  'BERMUDA': 'bm',
  'BRUNEI DARUSSALAM': 'bn',
  'BOLIVIA': 'bo',
  'BONAIRE, SINT EUSTATIUS AND SABA': 'bq',
  'BRAZIL': 'br',
  'BAHAMAS': 'bs',
  'BHUTAN': 'bt',
  'BOUVET ISLAND': 'bv',
  'BOTSWANA': 'bw',
  'BELARUS': 'by',
  'BELIZE': 'bz',
  'CANADA': 'ca',
  'COCOS (KEELING) ISLANDS': 'cc',
  'CONGO, DEMOCRATIC REPUBLIC OF THE': 'cd',
  'CENTRAL AFRICAN REPUBLIC': 'cf',
  'CONGO': 'cg',
  'DR CONGO': 'cg',
  'SWITZERLAND': 'ch',
  'CÔTE D\'IVOIRE': 'ci',
  'COOK ISLANDS': 'ck',
  'CHILE': 'cl',
  'CAMEROON': 'cm',
  'CHINA': 'cn',
  'COLOMBIA': 'co',
  'COSTA RICA': 'cr',
  'CUBA': 'cu',
  'CABO VERDE': 'cv',
  'CURAÇAO': 'cw',
  'CHRISTMAS ISLAND': 'cx',
  'CYPRUS': 'cy',
  'CZECH REPUBLIC': 'cz',
  "CZECHIA": 'cz',
  'GERMANY': 'de',
  'DJIBOUTI': 'dj',
  'DENMARK': 'dk',
  'DOMINICA': 'dm',
  'ALGERIA': 'dz',
  'ECUADOR': 'ec',
  'ESTONIA': 'ee',
  'EGYPT': 'eg',
  'WESTERN SAHARA': 'eh',
  'ERITREA': 'er',
  'SPAIN': 'es',
  'ETHIOPIA': 'et',
  'FINLAND': 'fi',
  'FIJI': 'fj',
  'FALKLAND ISLANDS (MALVINAS)': 'fk',
  'MICRONESIA, FEDERATED STATES OF': 'fm',
  'FAROE ISLANDS': 'fo',
  'FRANCE': 'fr',
  'GABON': 'ga',
  'UNITED KINGDOM': 'gb',
  'GRENADA': 'gd',
  'GEORGIA': 'ge',
  'FRENCH GUIANA': 'gf',
  'GUERNSEY': 'gg',
  'GHANA': 'gh',
  'GIBRALTAR': 'gi',
  'GREENLAND': 'gl',
  'GAMBIA': 'gm',
  'GUINEA': 'gn',
  'GUADELOUPE': 'gp',
  'EQUATORIAL GUINEA': 'gq',
  'GREECE': 'gr',
  'SOUTH GEORGIA AND THE SOUTH SANDWICH ISLANDS': 'gs',
  'GUATEMALA': 'gt',
  'GUAM': 'gu',
  'GUINEA-BISSAU': 'gw',
  'GUYANA': 'gy',
  'HONG KONG': 'hk',
  'HEARD ISLAND AND MCDONALD ISLANDS': 'hm',
  'HONDURAS': 'hn',
  'CROATIA': 'hr',
  'HAITI': 'ht',
  'HUNGARY': 'hu',
  'INDONESIA': 'id',
  'IRELAND': 'ie',
  'ISRAEL': 'il',
  'ISLE OF MAN': 'im',
  'BRITISH INDIAN OCEAN TERRITORY': 'io',
  'IRAQ': 'iq',
  'IRAN, ISLAMIC REPUBLIC OF': 'ir',
  'ICELAND': 'is',
  'ITALY': 'it',
  'INDIA': 'in',
  'JERSEY': 'je',
  'JAMAICA': 'jm',
  'JORDAN': 'jo',
  'JAPAN': 'jp',
  'KENYA': 'ke',
  'KYRGYZSTAN': 'kg',
  'CAMBODIA': 'kh',
  'KIRIBATI': 'ki',
  'COMOROS': 'km',
  'SAINT KITTS AND NEVIS': 'kn',
  'KOREA, DEMOCRATIC PEOPLE\'S REPUBLIC OF': 'kp',
  'KOREA, REPUBLIC OF': 'kr',
  'SOUTH KOREA': 'kp',
  'KUWAIT': 'kw',
  'CAYMAN ISLANDS': 'ky',
  'KAZAKHSTAN': 'kz',
  'LAO PEOPLE\'S DEMOCRATIC REPUBLIC': 'la',
  'LEBANON': 'lb',
  'SAINT LUCIA': 'lc',
  'LIECHTENSTEIN': 'li',
  'SRI LANKA': 'lk',
  'LIBERIA': 'lr',
  'LESOTHO': 'ls',
  'LITHUANIA': 'lt',
  'LUXEMBOURG': 'lu',
  'LATVIA': 'lv',
  'LIBYA': 'ly',
  'MOROCCO': 'ma',
  'MONACO': 'mc',
  'MOLDOVA, REPUBLIC OF': 'md',
  'MOLDOVA': 'md',
  'MONTENEGRO': 'me',
  'SAINT MARTIN (FRENCH PART)': 'mf',
  'MADAGASCAR': 'mg',
  'MARSHALL ISLANDS': 'mh',
  'NORTH MACEDONIA': 'mk',
  'MALI': 'ml',
  'MYANMAR': 'mm',
  'MONGOLIA': 'mn',
  'MACAO': 'mo',
  'NORTHERN MARIANA ISLANDS': 'mp',
  'MARTINIQUE': 'mq',
  'MAURITANIA': 'mr',
  'MONTSERRAT': 'ms',
  'MALTA': 'mt',
  'MAURITIUS': 'mu',
  'MALDIVES': 'mv',
  'MALAWI': 'mw',
  'MEXICO': 'mx',
  'MALAYSIA': 'my',
  'MOZAMBIQUE': 'mz',
  'NAMIBIA': 'na',
  'NEW CALEDONIA': 'nc',
  'NIGER': 'ne',
  'NORFOLK ISLAND': 'nf',
  'NIGERIA': 'ng',
  'NICARAGUA': 'ni',
  'NETHERLANDS': 'nl',
  "THE NETHERLANDS": 'nl',
  'NORWAY': 'no',
  'NEPAL': 'np',
  'NAURU': 'nr',
  'NU': 'nu',
  'NEW ZEALAND': 'nz',
  'OMAN': 'om',
  'PANAMA': 'pa',
  'PERU': 'pe',
  'FRENCH POLYNESIA': 'pf',
  'PAPUA NEW GUINEA': 'pg',
  'PHILIPPINES': 'ph',
  'PAKISTAN': 'pk',
  'POLAND': 'pl',
  'SAINT PIERRE AND MIQUELON': 'pm',
  'PITCAIRN': 'pn',
  'PUERTO RICO': 'pr',
  'PALESTINE, STATE OF': 'ps',
  'PORTUGAL': 'pt',
  'PALAU': 'pw',
  'PARAGUAY': 'py',
  'QATAR': 'qa',
  'RÉUNION': 're',
  'ROMANIA': 'ro',
  'SERBIA': 'rs',
  'RUSSIAN FEDERATION': 'ru',
  'RUSSIA': 'ru',
  'RWANDA': 'rw',
  'SAUDI ARABIA': 'sa',
  'SOLOMON ISLANDS': 'sb',
  'SEYCHELLES': 'sc',
  'SUDAN': 'sd',
  'SWEDEN': 'se',
  'SINGAPORE': 'sg',
  'SAINT HELENA, ASCENSION AND TRISTAN DA CUNHA': 'sh',
  'SLOVENIA': 'si',
  'SVALBARD AND JAN MAYEN': 'sj',
  'SLOVAKIA': 'sk',
  'SIERRA LEONE': 'sl',
  'SAN MARINO': 'sm',
  'SENEGAL': 'sn',
  'SOMALIA': 'so',
  'SURINAME': 'sr',
  'SOUTH SUDAN': 'ss',
  "SOUTH AFRICA": "za",
  'SAO TOME AND PRINCIPE': 'st',
  'EL SALVADOR': 'sv',
  'SINT MAARTEN (DUTCH PART)': 'sx',
  'SYRIAN ARAB REPUBLIC': 'sy',
  'SWAZILAND': 'sz',
  'TURKS AND CAICOS ISLANDS': 'tc',
  'CHAD': 'td',
  'FRENCH SOUTHERN TERRITORIES': 'tf',
  'TOGO': 'tg',
  'THAILAND': 'th',
  'TAJIKISTAN': 'tj',
  'TOKELAU': 'tk',
  'TIMOR-LESTE': 'tl',
  'TURKMENISTAN': 'tm',
  'TUNISIA': 'tn',
  'TONGA': 'to',
  'TURKEY': 'tr',
  'TÜRKIYE': 'tr',
  'TRINIDAD AND TOBAGO': 'tt',
  'TUVALU': 'tv',
  'VIETNAM': 'vn',
  'TAIWAN, PROVINCE OF CHINA': 'tw',
  'TAIWAN': 'tw',
  'TANZANIA, UNITED REPUBLIC OF': 'tz',
  'UKRAINE': 'ua',
  'UGANDA': 'ug',
  'UNITED STATES MINOR OUTLYING ISLANDS': 'um',
  'UNITED STATES': 'us',
  'URUGUAY': 'uy',
  'UZBEKISTAN': 'uz',
  'HOLY SEE': 'va',
  'SAINT VINCENT AND THE GRENADINES': 'vc',
  'VENEZUELA, BOLIVARIAN REPUBLIC OF': 've',
  'VIRGIN ISLANDS, BRITISH': 'vg',
}

export const getFlag = function(serverData, countryName) {
  const countryCode = countryMapping[serverData.location_country_code.toUpperCase()]
  if (!countryCode) {
    return [null, null]
  }
  if (countryCode == 'ad') { return [ad, countryCode] };
  if (countryCode == 'ae') { return [ae, countryCode] };
  if (countryCode == 'af') { return [af, countryCode] };
  if (countryCode == 'ag') { return [ag, countryCode] };
  if (countryCode == 'ai') { return [ai, countryCode] };
  if (countryCode == 'al') { return [al, countryCode] };
  if (countryCode == 'am') { return [am, countryCode] };
  if (countryCode == 'ao') { return [ao, countryCode] };
  if (countryCode == 'aq') { return [aq, countryCode] };
  if (countryCode == 'ar') { return [ar, countryCode] };
  if (countryCode == 'as') { return [as, countryCode] };
  if (countryCode == 'at') { return [at, countryCode] };
  if (countryCode == 'au') { return [au, countryCode] };
  if (countryCode == 'aw') { return [aw, countryCode] };
  if (countryCode == 'ax') { return [ax, countryCode] };
  if (countryCode == 'az') { return [az, countryCode] };
  if (countryCode == 'ba') { return [ba, countryCode] };
  if (countryCode == 'bb') { return [bb, countryCode] };
  if (countryCode == 'bd') { return [bd, countryCode] };
  if (countryCode == 'be') { return [be, countryCode] };
  if (countryCode == 'bf') { return [bf, countryCode] };
  if (countryCode == 'bg') { return [bg, countryCode] };
  if (countryCode == 'bh') { return [bh, countryCode] };
  if (countryCode == 'bi') { return [bi, countryCode] };
  if (countryCode == 'bj') { return [bj, countryCode] };
  if (countryCode == 'bl') { return [bl, countryCode] };
  if (countryCode == 'bm') { return [bm, countryCode] };
  if (countryCode == 'bn') { return [bn, countryCode] };
  if (countryCode == 'bo') { return [bo, countryCode] };
  if (countryCode == 'bq') { return [bq, countryCode] };
  if (countryCode == 'br') { return [br, countryCode] };
  if (countryCode == 'bs') { return [bs, countryCode] };
  if (countryCode == 'bt') { return [bt, countryCode] };
  if (countryCode == 'bv') { return [bv, countryCode] };
  if (countryCode == 'bw') { return [bw, countryCode] };
  if (countryCode == 'by') { return [by, countryCode] };
  if (countryCode == 'bz') { return [bz, countryCode] };
  if (countryCode == 'ca') { return [ca, countryCode] };
  if (countryCode == 'cc') { return [cc, countryCode] };
  if (countryCode == 'cd') { return [cd, countryCode] };
  if (countryCode == 'cf') { return [cf, countryCode] };
  if (countryCode == 'cg') { return [cg, countryCode] };
  if (countryCode == 'ch') { return [ch, countryCode] };
  if (countryCode == 'ci') { return [ci, countryCode] };
  if (countryCode == 'ck') { return [ck, countryCode] };
  if (countryCode == 'cl') { return [cl, countryCode] };
  if (countryCode == 'cm') { return [cm, countryCode] };
  if (countryCode == 'cn') { return [cn, countryCode] };
  if (countryCode == 'co') { return [co, countryCode] };
  if (countryCode == 'cr') { return [cr, countryCode] };
  if (countryCode == 'cu') { return [cu, countryCode] };
  if (countryCode == 'cv') { return [cv, countryCode] };
  if (countryCode == 'cw') { return [cw, countryCode] };
  if (countryCode == 'cx') { return [cx, countryCode] };
  if (countryCode == 'cy') { return [cy, countryCode] };
  if (countryCode == 'cz') { return [cz, countryCode] };
  if (countryCode == 'de') { return [de, countryCode] };
  if (countryCode == 'dj') { return [dj, countryCode] };
  if (countryCode == 'dk') { return [dk, countryCode] };
  if (countryCode == 'dm') { return [dm, countryCode] };
  if (countryCode == 'dz') { return [dz, countryCode] };
  if (countryCode == 'ec') { return [ec, countryCode] };
  if (countryCode == 'ee') { return [ee, countryCode] };
  if (countryCode == 'eg') { return [eg, countryCode] };
  if (countryCode == 'eh') { return [eh, countryCode] };
  if (countryCode == 'er') { return [er, countryCode] };
  if (countryCode == 'es') { return [es, countryCode] };
  if (countryCode == 'et') { return [et, countryCode] };
  if (countryCode == 'fi') { return [fi, countryCode] };
  if (countryCode == 'fj') { return [fj, countryCode] };
  if (countryCode == 'fk') { return [fk, countryCode] };
  if (countryCode == 'fm') { return [fm, countryCode] };
  if (countryCode == 'fo') { return [fo, countryCode] };
  if (countryCode == 'fr') { return [fr, countryCode] };
  if (countryCode == 'ga') { return [ga, countryCode] };
  if (countryCode == 'gb') { return [gb, countryCode] };
  if (countryCode == 'gd') { return [gd, countryCode] };
  if (countryCode == 'ge') { return [ge, countryCode] };
  if (countryCode == 'gf') { return [gf, countryCode] };
  if (countryCode == 'gg') { return [gg, countryCode] };
  if (countryCode == 'gh') { return [gh, countryCode] };
  if (countryCode == 'gi') { return [gi, countryCode] };
  if (countryCode == 'gl') { return [gl, countryCode] };
  if (countryCode == 'gm') { return [gm, countryCode] };
  if (countryCode == 'gn') { return [gn, countryCode] };
  if (countryCode == 'gp') { return [gp, countryCode] };
  if (countryCode == 'gq') { return [gq, countryCode] };
  if (countryCode == 'gr') { return [gr, countryCode] };
  if (countryCode == 'gs') { return [gs, countryCode] };
  if (countryCode == 'gt') { return [gt, countryCode] };
  if (countryCode == 'gu') { return [gu, countryCode] };
  if (countryCode == 'gw') { return [gw, countryCode] };
  if (countryCode == 'gy') { return [gy, countryCode] };
  if (countryCode == 'hk') { return [hk, countryCode] };
  if (countryCode == 'hm') { return [hm, countryCode] };
  if (countryCode == 'hn') { return [hn, countryCode] };
  if (countryCode == 'hr') { return [hr, countryCode] };
  if (countryCode == 'ht') { return [ht, countryCode] };
  if (countryCode == 'hu') { return [hu, countryCode] };
  if (countryCode == 'id') { return [id, countryCode] };
  if (countryCode == 'ie') { return [ie, countryCode] };
  if (countryCode == 'il') { return [il, countryCode] };
  if (countryCode == 'im') { return [im, countryCode] };
  if (countryCode == 'io') { return [io, countryCode] };
  if (countryCode == 'iq') { return [iq, countryCode] };
  if (countryCode == 'ir') { return [ir, countryCode] };
  if (countryCode == 'is') { return [is, countryCode] };
  if (countryCode == 'it') { return [it, countryCode] };
  if (countryCode == 'je') { return [je, countryCode] };
  if (countryCode == 'jm') { return [jm, countryCode] };
  if (countryCode == 'jo') { return [jo, countryCode] };
  if (countryCode == 'jp') { return [jp, countryCode] };
  if (countryCode == 'ke') { return [ke, countryCode] };
  if (countryCode == 'kg') { return [kg, countryCode] };
  if (countryCode == 'kh') { return [kh, countryCode] };
  if (countryCode == 'ki') { return [ki, countryCode] };
  if (countryCode == 'km') { return [km, countryCode] };
  if (countryCode == 'kn') { return [kn, countryCode] };
  if (countryCode == 'kp') { return [kp, countryCode] };
  if (countryCode == 'kr') { return [kr, countryCode] };
  if (countryCode == 'kw') { return [kw, countryCode] };
  if (countryCode == 'ky') { return [ky, countryCode] };
  if (countryCode == 'kz') { return [kz, countryCode] };
  if (countryCode == 'la') { return [la, countryCode] };
  if (countryCode == 'lb') { return [lb, countryCode] };
  if (countryCode == 'lc') { return [lc, countryCode] };
  if (countryCode == 'li') { return [li, countryCode] };
  if (countryCode == 'lk') { return [lk, countryCode] };
  if (countryCode == 'lr') { return [lr, countryCode] };
  if (countryCode == 'ls') { return [ls, countryCode] };
  if (countryCode == 'lt') { return [lt, countryCode] };
  if (countryCode == 'lu') { return [lu, countryCode] };
  if (countryCode == 'lv') { return [lv, countryCode] };
  if (countryCode == 'ly') { return [ly, countryCode] };
  if (countryCode == 'ma') { return [ma, countryCode] };
  if (countryCode == 'mc') { return [mc, countryCode] };
  if (countryCode == 'md') { return [md, countryCode] };
  if (countryCode == 'me') { return [me, countryCode] };
  if (countryCode == 'mf') { return [mf, countryCode] };
  if (countryCode == 'mg') { return [mg, countryCode] };
  if (countryCode == 'mh') { return [mh, countryCode] };
  if (countryCode == 'mk') { return [mk, countryCode] };
  if (countryCode == 'ml') { return [ml, countryCode] };
  if (countryCode == 'mm') { return [mm, countryCode] };
  if (countryCode == 'mn') { return [mn, countryCode] };
  if (countryCode == 'mo') { return [mo, countryCode] };
  if (countryCode == 'mp') { return [mp, countryCode] };
  if (countryCode == 'mq') { return [mq, countryCode] };
  if (countryCode == 'mr') { return [mr, countryCode] };
  if (countryCode == 'ms') { return [ms, countryCode] };
  if (countryCode == 'mt') { return [mt, countryCode] };
  if (countryCode == 'mu') { return [mu, countryCode] };
  if (countryCode == 'mv') { return [mv, countryCode] };
  if (countryCode == 'mw') { return [mw, countryCode] };
  if (countryCode == 'mx') { return [mx, countryCode] };
  if (countryCode == 'my') { return [my, countryCode] };
  if (countryCode == 'mz') { return [mz, countryCode] };
  if (countryCode == 'na') { return [na, countryCode] };
  if (countryCode == 'nc') { return [nc, countryCode] };
  if (countryCode == 'ne') { return [ne, countryCode] };
  if (countryCode == 'nf') { return [nf, countryCode] };
  if (countryCode == 'ng') { return [ng, countryCode] };
  if (countryCode == 'ni') { return [ni, countryCode] };
  if (countryCode == 'nl') { return [nl, countryCode] };
  if (countryCode == 'no') { return [no, countryCode] };
  if (countryCode == 'np') { return [np, countryCode] };
  if (countryCode == 'nr') { return [nr, countryCode] };
  if (countryCode == 'nu') { return [nu, countryCode] };
  if (countryCode == 'nz') { return [nz, countryCode] };
  if (countryCode == 'om') { return [om, countryCode] };
  if (countryCode == 'pa') { return [pa, countryCode] };
  if (countryCode == 'pe') { return [pe, countryCode] };
  if (countryCode == 'pf') { return [pf, countryCode] };
  if (countryCode == 'pg') { return [pg, countryCode] };
  if (countryCode == 'ph') { return [ph, countryCode] };
  if (countryCode == 'pk') { return [pk, countryCode] };
  if (countryCode == 'pl') { return [pl, countryCode] };
  if (countryCode == 'pm') { return [pm, countryCode] };
  if (countryCode == 'pn') { return [pn, countryCode] };
  if (countryCode == 'pr') { return [pr, countryCode] };
  if (countryCode == 'ps') { return [ps, countryCode] };
  if (countryCode == 'pt') { return [pt, countryCode] };
  if (countryCode == 'pw') { return [pw, countryCode] };
  if (countryCode == 'py') { return [py, countryCode] };
  if (countryCode == 'qa') { return [qa, countryCode] };
  if (countryCode == 're') { return [re, countryCode] };
  if (countryCode == 'ro') { return [ro, countryCode] };
  if (countryCode == 'rs') { return [rs, countryCode] };
  if (countryCode == 'ru') { return [ru, countryCode] };
  if (countryCode == 'rw') { return [rw, countryCode] };
  if (countryCode == 'sa') { return [sa, countryCode] };
  if (countryCode == 'sb') { return [sb, countryCode] };
  if (countryCode == 'sc') { return [sc, countryCode] };
  if (countryCode == 'sd') { return [sd, countryCode] };
  if (countryCode == 'se') { return [se, countryCode] };
  if (countryCode == 'sg') { return [sg, countryCode] };
  if (countryCode == 'sh') { return [sh, countryCode] };
  if (countryCode == 'si') { return [si, countryCode] };
  if (countryCode == 'sj') { return [sj, countryCode] };
  if (countryCode == 'sk') { return [sk, countryCode] };
  if (countryCode == 'sl') { return [sl, countryCode] };
  if (countryCode == 'sm') { return [sm, countryCode] };
  if (countryCode == 'sn') { return [sn, countryCode] };
  if (countryCode == 'so') { return [so, countryCode] };
  if (countryCode == 'sr') { return [sr, countryCode] };
  if (countryCode == 'ss') { return [ss, countryCode] };
  if (countryCode == 'st') { return [st, countryCode] };
  if (countryCode == 'sv') { return [sv, countryCode] };
  if (countryCode == 'sx') { return [sx, countryCode] };
  if (countryCode == 'sy') { return [sy, countryCode] };
  if (countryCode == 'sz') { return [sz, countryCode] };
  if (countryCode == 'tc') { return [tc, countryCode] };
  if (countryCode == 'td') { return [td, countryCode] };
  if (countryCode == 'tf') { return [tf, countryCode] };
  if (countryCode == 'tg') { return [tg, countryCode] };
  if (countryCode == 'th') { return [th, countryCode] };
  if (countryCode == 'tj') { return [tj, countryCode] };
  if (countryCode == 'tk') { return [tk, countryCode] };
  if (countryCode == 'tl') { return [tl, countryCode] };
  if (countryCode == 'tm') { return [tm, countryCode] };
  if (countryCode == 'tn') { return [tn, countryCode] };
  if (countryCode == 'to') { return [to, countryCode] };
  if (countryCode == 'tr') { return [tr, countryCode] };
  if (countryCode == 'tt') { return [tt, countryCode] };
  if (countryCode == 'tv') { return [tv, countryCode] };
  if (countryCode == 'tw') { return [tw, countryCode] };
  if (countryCode == 'tz') { return [tz, countryCode] };
  if (countryCode == 'ua') { return [ua, countryCode] };
  if (countryCode == 'ug') { return [ug, countryCode] };
  if (countryCode == 'um') { return [um, countryCode] };
  if (countryCode == 'us') { return [us, countryCode] };
  if (countryCode == 'uy') { return [uy, countryCode] };
  if (countryCode == 'uz') { return [uz, countryCode] };
  if (countryCode == 'va') { return [va, countryCode] };
  if (countryCode == 'vc') { return [vc, countryCode] };
  if (countryCode == 've') { return [ve, countryCode] };
  if (countryCode == 'vg') { return [vg, countryCode] };
  if (countryCode == 'vi') { return [vi, countryCode] };
  if (countryCode == 'vn') { return [vn, countryCode] };
  if (countryCode == 'vu') { return [vu, countryCode] };
  if (countryCode == 'wf') { return [wf, countryCode] };
  if (countryCode == 'ws') { return [ws, countryCode] };
  if (countryCode == 'ye') { return [ye, countryCode] };
  if (countryCode == 'yt') { return [yt, countryCode] };
  if (countryCode == 'za') { return [za, countryCode] };
  if (countryCode == 'zm') { return [zm, countryCode] };
  if (countryCode == 'zw') { return [zw, countryCode] };
  if (countryCode == 'in') { return [india, countryCode] };
  debugger
  return [null, null]
};
